import { PermissionCheckMixin, LABEL_VERIFICATION_REPORT, LABEL_VERIFICATION_VERIFYING_LABEL, LABEL_VERIFICATION_OPERATORS, VIEW_PERMISSION } from "@kraftheinz/common";
import { n as normalizeComponent, L as LabelVerificationFilter } from "./index.js";
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "body" }, [_c("portal", { attrs: { "to": "sider-items" } }, [_c("div", { staticClass: "kh-application p-4" }, [_c("p", { staticClass: "m-0 text-uppercase" }, [_vm._v("Supply chain ops")]), _c("h5", { staticClass: "mb-0 text-capitalize" }, [_vm._v("Label Verification")])]), _c("a-menu", { attrs: { "open-keys": _vm.openKeys, "mode": "inline", "theme": "light", "inline-collapsed": _vm.collapsed, "selected-keys": _vm.selectedKeys } }, [_vm._l(_vm.dataMenu, function(item) {
    return [item.child.length === 0 && item.key === "home" || _vm.$can(_vm.permissions.view, item.page) ? [_c("a-menu-item", { key: item.key, on: { "click": function($event) {
      return _vm.clickItem($event);
    } } }, [_c("router-link", { attrs: { "to": item.path } }, [_c("a-icon", { attrs: { "type": item.icon } }), _c("span", [_vm._v(_vm._s(item.title))])], 1)], 1)] : _vm._e(), item.child.length > 0 && _vm.checkChildPermission(item.child) ? [_c("a-sub-menu", { key: item.key, on: { "titleClick": function($event) {
      return _vm.clickParentItem($event);
    } } }, [_c("span", { attrs: { "slot": "title" }, slot: "title" }, [_c("a-icon", { attrs: { "type": item.icon } }), _c("span", [_vm._v(_vm._s(item.title))])], 1), _vm._l(item.child, function(itemChild) {
      return [_vm.$can(_vm.permissions.view, itemChild.page) ? _c("a-menu-item", { key: itemChild.key, on: { "click": function($event) {
        return _vm.clickItem($event);
      } } }, [_c("router-link", { attrs: { "to": itemChild.path } }, [_vm._v(_vm._s(itemChild.title))])], 1) : _vm._e()];
    })], 2)] : _vm._e()];
  })], 2)], 1), _c("portal", { attrs: { "to": "facility-select" } }, [_c("label-verification-filter", { on: { "facilityChange": _vm.onFacilityChange, "areaChange": _vm.onAreaChange } })], 1), _vm.showChildRoute ? _c("router-view") : _vm._e()], 1);
};
var staticRenderFns = [];
var LabelVerification_vue_vue_type_style_index_0_scoped_true_lang = "";
const __vue2_script = {
  name: "LabelVerification",
  components: { LabelVerificationFilter },
  mixins: [PermissionCheckMixin],
  provide() {
    return {
      getFacility: () => this.facilityValue,
      getArea: () => this.areaValue
    };
  },
  data() {
    return {
      collapsed: false,
      showChildRoute: false,
      dataMenu: [
        {
          title: "Back to Main Menu",
          path: "/",
          key: "home",
          icon: "appstore",
          child: []
        },
        {
          title: "Report",
          path: "/label-verification/report",
          key: "report",
          icon: "bar-chart",
          page: LABEL_VERIFICATION_REPORT,
          child: []
        },
        {
          title: "Verifying Label",
          path: "/label-verification/verifying-label",
          key: "verifying-label",
          icon: "qrcode",
          page: LABEL_VERIFICATION_VERIFYING_LABEL,
          child: []
        },
        {
          title: "Master Files",
          path: "",
          key: "masterFiles",
          icon: "tool",
          child: [
            {
              title: "Operators",
              path: "/label-verification/operators",
              key: "operators",
              page: LABEL_VERIFICATION_OPERATORS,
              child: []
            }
          ]
        }
      ],
      permissions: {
        view: VIEW_PERMISSION
      },
      selectedKeys: [],
      openKeys: [],
      rootSubmenuKeys: [],
      facilityValue: null,
      areaValue: null,
      windowWidth: window.innerWidth,
      collapsedWidth: 1024
    };
  },
  metaInfo: {
    title: "Label Verification"
  },
  watch: {
    $route() {
      this.updateMenu();
    },
    windowWidth(newVal) {
      if (newVal <= this.collapsedWidth) {
        this.$emit("collapsedMounted", true);
      }
    }
  },
  async created() {
    this.updateMenu();
    this.showChildRoute = true;
    if (this.windowWidth <= this.collapsedWidth) {
      this.$emit("collapsedMounted", true);
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    updateMenu: function() {
      if (!this.$route.matched[1])
        return;
      let path = this.$route.matched[1].path;
      this.openKeys = typeof this.$route.matched[1].meta == "string" ? [this.$route.matched[1].meta.split(";")[0]] : [];
      let title = path.substring(path.lastIndexOf("/") + 1);
      this.selectedKeys = [title];
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    onFacilityChange(facility) {
      this.facilityValue = facility;
    },
    onAreaChange(area) {
      this.showChildRoute = false;
      this.areaValue = area;
      this.$nextTick(() => this.showChildRoute = true);
    },
    checkChildPermission(listMenuItem) {
      return listMenuItem.some((itemChild) => {
        if (itemChild.child.length > 0) {
          this.checkChildPermission(itemChild.child);
          return true;
        } else {
          return this.$can(this.permissions.view, itemChild.page);
        }
      });
    },
    clickItem: function(e) {
      this.selectedKeys = [e.key];
    },
    clickParentItem(e) {
      if (!this.openKeys.includes(e.key)) {
        this.openKeys = [...this.openKeys, e.key];
      } else {
        const keyIndex = this.openKeys.indexOf(e.key);
        if (keyIndex > -1)
          this.openKeys.splice(keyIndex, 1);
      }
    }
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, "7aac0212", null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var LabelVerification = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { LabelVerification as default };
